<template>
  <div>
    <el-tabs
      :value="$route.name"
      type="border-card"
      @tab-click="$route.name !== $event.name ? $router.push({ name: $event.name }) : null"
    >
      <el-tab-pane
        v-for="tab in tabs"
        :key="tab.name"
        :label="tab.label"
        :name="tab.name"
      />
      <keep-alive>
        <router-view :key="$route.fullPath" />
      </keep-alive>
    </el-tabs>
  </div>
</template>

<script>
/**
 * BaseReport
 *
 * Parent component for all property report pages.
 * Each report will be rendered in a tab.
 *
 * `tab.name` must be a valid route name.
 *
 * Router view will be cached to avoid re-fetching
 * data when switching between tabs.
 */
export default {
  name: 'BaseReport',
  data () {
    return {
      tabs: [
        { label: 'Onboarding', name: 'OnboardingReport' },
        { label: 'Offboarding', name: 'OffboardingReport' },
        { label: 'Cancelled', name: 'CancelledReport' }
      ]
    }
  },
  async created () {
    // Make sure group list is up-to-date
    await this.$store.dispatch('fetch_company_list')
  }
}
</script>
